import React from 'react'
import Particles from 'react-particles-js'

const Main = () => (
    <div>
        <Particles
            className="backdrop"
            params={{
                particles: {
                    "color": {
                        "value": "#55E6C1"
                    },
                    number: {
                        value: 50,
                        density: {
                            enable: true,
                            value_area: 500
                        }

                    },
                    line_linked: {
                        enable: true,
                        opacity: 0.5
                    },
                    move: {
                        direction: "right",
                        speed: 3
                    },
                    size: {
                        value: 5
                    },
                    opacity: {
                        anim: {
                            enable: true,
                            speed: 2,
                            opacity_min: 0.1
                        }
                    }
                },
                retina_detect: true
            }}
        />
        <section className="hero primary first is-fullheight">

            <div className='hero-body'>
                <div className="container">
                    <div className="columns center">
                        <div className="column is-three-quarters">
                            <h1 className="is-white is-size-2" style={{ textTransform: "uppercase", fontWeight: 'bold' }}>GlobalMaxima</h1>
                            <p className=" is-white is-size-4">
                                Your Video. Your Security. Coming soon
                            </p>

                            <p className="is-white is-size-6">
                                VPS hosted video conferencing as a service. Your choice of cloud provider, hardware and location.
                                Using signed open source software packages and end to end encryption. Pay by the minute, and destroy everything when finished.
                            </p>

                            <p className=" is-white is-size-6">
                                For more info, email us at <br />
                                <a href="mailto:hello@globalmaxima.dev">
                                    hello@globalmaxima.dev
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div >
)


export default Main
