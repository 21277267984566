import React from "react";

import "./style.scss";
import Helmet from "./helmet";
import Main from "./main";


const Layout = () => (
  <div>
    <Helmet />
    <Main />
  </div>
);

export default Layout;
